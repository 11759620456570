/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2025-02-12 15:20:46
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-12 16:14:05
 * @FilePath: \maishiqi_web\src\pages\Home\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 17:19:28
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-12 15:31:51
 * @FilePath: \line_web\src\pages\Home\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './index.scss'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'

const Home = () => {
  const swiperList = [
    require('@/assets/images/swiper_1.jpg'),
    require('@/assets/images/swiper_2.jpg'),
    require('@/assets/images/swiper_3.jpg'),
    require('@/assets/images/swiper_4.jpg'),
    require('@/assets/images/swiper_5.jpg')
  ]
  return <div className="home_page">
    <div className="bg_box" />
    <div className="logo_box">
      <img className="img_box" alt="logo" src={require('@/assets/images/logo.png')} />
      <p className="text_box">迈势绮</p>
    </div>
    <div className="main_box">
      <div className="phone_box">
        <img className="img_box" alt="phone" src={require('@/assets/images/phone.png')} />
        <Swiper
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false
          }}
          className="swiper_list"
        >
          {swiperList.map((item, index) =>
            <SwiperSlide className="swiper_item" key={index}><img className="img_box" alt="phone" src={item} /></SwiperSlide>
          )}
        </Swiper>
      </div>
      <div className="text_box">
        <p className="title_box">迈势绮 APP</p>
        <div className="text_box">
          <p>一款丰富社交生活的全新应用</p>
          <p>平台上还可以找到校园同学</p>
          <p>提供简单、高效、有趣的社交体验</p>
        </div>
        {/* <div className="btn_box">
          <div className="btn_list">
            <a className="btn_item">
              <img className="img_box" alt="ios" src={require('@/assets/images/ios_btn.jpg')} />
            </a>
            <a className="btn_item">
              <img className="img_box" alt="android" src={require('@/assets/images/android_btn.jpg')} />
            </a>
          </div>
          <div className="code_box">
            <img className="img_box" alt="code" src={require('@/assets/images/code.png')} />
            <p className="text_box">扫码下载遇见APP</p>
          </div>
        </div> */}
      </div>
    </div>
    <div className="tips_box">
      <p>上海迈势绮信息科技有限公司<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">沪ICP备2023019017号-1</a></p>
    </div>
  </div>
}
export default Home