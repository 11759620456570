/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 17:19:23
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-12 16:10:16
 * @FilePath: \line_web\src\pages\share\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './index.scss'
import { useSearchParams } from 'react-router-dom'
const Agreement = () => {
  const APPName = '迈势绮APP'
  const company = '上海迈势绮信息科技有限公司'
  const short = '迈势绮'
  const email = 'lusj@messagesit.com'
  const phone = '021 + 61428751'
  const address = '上海市静安区天目西路111号新梅华东1406室'
  const postcode = '200070'
  const signAddr = '上海市崇明区横沙乡富民支路58号（上海横泰经济开发区）'
  const [params] = useSearchParams()
  let pageType = params.get('pageType')
  return (
    <div className="privacy_policy_page">
      <div className="title_box">{pageType === 'user' ? '用户协议' : '隐私政策'}</div>
      {
        pageType === 'user' ?
          <div className="main_box">
            <div className="text_box">
              <p className="text"><b>更新日期：2023年6月5日</b></p>
              <p className="text"><b>更新日期：2023年6月5日</b></p>
            </div>
            <div className="text_box">
              <p className="title">一、【前言】</p>
              <p className="text">
                <b>我们的用户协议已于【2023】年【6】月【5】日更新。</b>
                我们对用户协议进行了修订，请您务必在使用{
                  APPName
                }产品之前，认真完整地阅读并理解、同意我们的《用户协议》。
                <b>
                  如您为未成年人，请您确保您阅读本《用户协议》及确认的行为系在父母或监护人的指导下完成且征得父母或监护人的同意。
                </b>
                如果您有任何问题，
                <b>请通过{email}告诉我们。</b>
              </p>
              <p className="text">
                本用户协议一经正式发布，并以适当的方式送达用户（站内信、系统通知等），即为本协议不可分割的组成部分，您应同样遵守。若您不接受修改后的条款，请立即停止使用服务，您继续使用服务视为接受修改后的协议。
              </p>
              <p className="text">
                用户协议（以下简称“本协议”）是您（或称“用户”，指注册、登录、使用、浏览本服务的个人或组织）与{
                  company
                }（注册地址：{signAddr}）之间关于{
                  APPName
                }网站（http://www.messagesit.com，简称本网站）与{
                  APPName
                }产品（以下简称“本软件”）和扩展服务所订立的协议。
              </p>
            </div>
            <div className="text_box">
              <p className="title">二、【释义】（包含但不限于）</p>
              <p className="text">
                1.{APPName}用户：是指使用{APPName}服务软件的广大用户。
              </p>
              <p className="text">
                2.{short}公司：是指注册地址位于{signAddr}的{company}。
              </p>
              <p className="text">
                3.{APPName}：是指{company}开发并运营的移动端应用程序APP。
              </p>
            </div>
            <div className="text_box">
              <p className="title">三、【特别声明】</p>
              <p className="text">
                <b>
                  在使用本软件和扩展服务时您将受到本协议的约束，所以请您在使用本服务前务必阅读并理解{
                    short
                  }提供的《用户协议》及《隐私政策》的所有条款。如果您选择主动勾选、主动点击“同意”、“注册”、“发送”、“下一步”、主动填写或提供、登录等，则将被视为您的肯定性授权，条款将被适用。若您拒绝选择，将导致您无法使用本软件或扩展服务。
                </b>
              </p>
              <p className="text">
                <b>
                  特别是免除或者限制责任的条款、收集个人信息以及开通或使用某项扩展服务的单独协议，您可以选择接受或不接受。限制、免责条款会以加粗形式提示您注意，拒绝某项扩展服务不会影响本软件基本服务和其他已授权扩展服务的使用或受到限制。
                </b>
              </p>
              <p className="text">
                若您虽已阅读本协议所有条款，但不接受其部分或全部内容的，我们建议您不使用或直接卸载本软件，以及停止使用本软件相关服务。
              </p>
              <p className="text">
                <b>
                  如果您未满18周岁，请在法定监护人的陪同下阅读本协议及其他条款，并特别注意未成年人使用条款。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">四、【关于本服务】</p>
              <p className="text"><b>(一)本服务的内容</b></p>
              <p className="text">
                指{short}向您提供的软件及扩展服务统称为本服务。
              </p>
              <p className="text"><b>(二)本服务的形式</b></p>
              <p className="text">
                1.您使用本服务需要下载本软件客户端软件，对于这些软件，{
                  short
                }给予您一项个人的、不可转让及非排他性的许可。您仅可为访问或使用本服务的合法目的而使用这些软件及服务。
              </p>
              <p className="text">
                2.本服务中本软件客户端软件提供包括但不限于iOS、Android等多个应用版本，请您选择与所安装终端设备相匹配的软件版本。
              </p>
              <p className="text">3.本服务许可的范围</p>
              <p className="text">
                (1)您可以为合法的商业目的或非商业目的在单一台终端设备上安装、使用、显示、运行本软件。
              </p>
              <p className="text">
                (2)本条及本协议其他条款未明示授权的其他一切权利仍由{
                  short
                }保留，您在行使这些权利时须另外取得{
                  short
                }的书面许可，未经许可使用将构成侵权，{
                  short
                }将有权向您要求停止侵权行为、禁止您使用相关软件及索赔等。
              </p>
              <p className="text">
                (3)
                <b>{short}如果未行使前述任何权利，并不构成对该权利的放弃。</b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">五、【软件的获取】</p>
              <p className="text">
                1.您可以直接从{short}的网站上获取本软件，也可以从得到{
                  short
                }授权的第三方获取。
              </p>
              <p className="text">
                2.如果您从未经{
                  short
                }授权的第三方获取本软件或与本软件名称相同的安装程序，{
                  short
                }无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
              </p>
            </div>
            <div className="text_box">
              <p className="title">六、【软件的安装与卸载】</p>
              <p className="text">
                1.{
                  short
                }可能为不同的终端设备开发了不同的软件版本，请您根据实际情况选择下载合适的版本进行安装。您不得将本软件安装在未经{
                  short
                }明示许可的其他终端设备上。否则您可能承担因未正确安装合适的软件版本所带来的风险及损失
              </p>
              <p className="text">
                2.下载安装程序后，您需要按照该程序提示的步骤正确安装。
              </p>
              <p className="text">
                3.为提供更加优质、安全的服务。
              </p>
              <p className="text">
                4.如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果您愿意帮助{
                  short
                }改进产品服务，请您告知卸载的原因。
              </p>
            </div>
            <div className="text_box">
              <p className="title">七、【软件的更新】</p>
              <p className="text">
                1.为了改善用户体验、完善服务内容，{
                  short
                }将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。
              </p>
              <p className="text">
                2.为了保证本软件及服务的安全性和功能的一致性，对于软件更新，{
                  short
                }将会向您推送软件更新信息并由您自行决定是否更新。{
                  short
                }有权因安全需要、版本升级需要、功能强化需要、有关政府部门要求对软件的部分功能效果进行改变或限制。
              </p>
              <p className="text">
                3.本软件新版本发布后，旧版本的软件可能无法使用。{
                  short
                }不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
              </p>
            </div>
            <div className="text_box">
              <p className="title">八、【用户个人信息保护】</p>
              <p className="text">
                <b>
                  1.保护您个人信息是{short}的一项基本原则（详见《隐私政策》），
                </b>
                {
                  short
                }将会采取符合业界标准的安全防护措施保护您的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
                <b>
                  除法律法规、个人信息保护国家标准豁免的情形及《隐私政策》另有规定外，未经您许可，{
                    short
                  }不会向第三方公开、透露、买卖您个人信息。
                </b>
              </p>
              <p className="text">
                2.{
                  short
                }的共同信息控制人（若有）将会被明确告知，且将同样遵循个人信息保护要求。若本服务中的部分产品或服务系由第三方提供，则该产品或服务将明示标识，如您对第三方的个人信息收集、使用行为有异议，可直接向第三方申诉，{
                  short
                }亦会履行督促和监督义务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。
              </p>
              <p className="text">
                3.{
                  short
                }收集个人信息后将及时采取去标识化处理，并对相关信息采取技术保护措施，通过加密存储、分开储存、加密传输、控制访问等保障您个人信息的安全。
              </p>
              <p className="text">
                4.您在注册账号或使用本服务的过程中，可能需要您提供一些个人信息，具体内容详见《隐私政策》。
              </p>
              <p className="text">
                5.一般情况下，您可随时访问、更正、删除、撤回同意自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，更正的频次我们将予以限制，注册时提供的初始信息和验证信息更正将经核查后方可予以更正。
              </p>
              <p className="text">
                6.{
                  short
                }将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露，但同时您也同意并理解，因技术限制及众多因素，如您的个人信息被侵害，{
                  short
                }将尽力配合您进行维权。
              </p>
              <p className="text">
                <b>
                  7.未满十八岁的未成年人使用我们的服务须在父母或监护人指导下进行。未成年人须真实填写用户个人信息，便于{
                    short
                  }对用户进行合理的保护。未成年人在阅读本协议及《隐私政策》时及提交个人信息之前，须在父母或监护人指导下进行并征得父母或监护人的同意。未成年人注册使用我们的服务的，我们将重点予以审核。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">九、【主权利义务条款】</p>
              <p className="text"><b>(一)账号使用规范</b></p>
              <p className="text">
                1.您无需注册账号即可浏览本网站。但某些网站功能、{
                  short
                }产品、程序和服务需要您注册本软件账号。本软件账号可通过手机号码进行注册。{
                  short
                }有权根据您需求或产品需要对账号注册和绑定的方式进行变更，关于您使用账号的具体规则，请遵守{
                  short
                }为此发布的专项规则及其他规则。
              </p>
              <p className="text">
                2.
                <b>
                  您了解并同意，本软件是一个【应用服务产品】，本软件账号的所有权归{
                    short
                  }所有，您完成申请注册手续后，仅获得本软件账号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖本软件账号或者以其他方式许可非初始申请注册人使用本软件账号；非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用本软件账号，否则可能产生因未遵守这些规则而导致账号未经授权而被使用的风险。
                </b>
              </p>
              <p className="text">
                3.
                <b>
                  您有责任妥善保管注册账号信息及账号信息的安全，您需要对注册账号以及账号信息下的行为承担法律责任。您同意在任何情况下不向他人透露账号及账号信息。当在您怀疑他人在使用您的账号时，您应立即通知{
                    short
                  }。
                </b>
              </p>
              <p className="text">
                4.
                <b>
                  您注册本软件账号后如果连续三个月不登录该账号，为避免造成资源浪费，{
                    short
                  }有权回收该账号。
                </b>
              </p>
              <p className="text">
                5.您有权更改、删除在本软件上的个人资料、注册信息及传送内容等，但您需注意，删除上述信息的同时也会产生您储存在系统中的文字和图片被删除的风险。
              </p>
              <p className="text">
                6.您可随时停止使用或卸载本软件，您停止使用或卸载本软件无需特别通知本公司。您停止使用或卸载本软件的，您根据您协议与{
                  short
                }达成的权利义务到此终止。
              </p>
              <p className="text">
                7.您在使用本软件时，{
                  short
                }可能会根据实际需要要求您提供相关资料（相关资料详见《隐私政策》）以进行账号注册。如您资料有任何变动，您需及时更新并通知{
                  short
                }。
              </p>
              <p className="text">
                8.您同意并保证给予{
                  short
                }的任何资料均是准确、完整和最新的。若您提供任何错误、不实、过时或不完整或具误导性的资料，或者{
                  short
                }有理由怀疑您提供的资料为错误、不实、过时或不完整或具误导性的，{
                  short
                }有权暂停或永久终止您的账号，并拒绝您继续使用本软件。
              </p>
              <p className="text"><b>(二)您注意事项</b></p>
              <p className="text">
                1.
                <b>
                  我们的某些产品服务必须在联网的情况下才可以使用。您必须自行负担个人上网或第三方（包括但不限于电信或移动通讯提供商）收取的通讯费、信息费等相关费用。如涉及电信增值服务，我们建议您与增值服务提供商确认相关费用问题。
                </b>
              </p>
              <p className="text">
                2.您理解并同意：本软件的某些功能可能会让第三方知晓您的信息，如您好友可以查询您头像、名字、账号等可公开的个人资料；其他方可以根据其功能权限获取关注您头像、名字、消息评论内容、图片、视频等信息
                <b>（相关内容详见《隐私政策》）</b>
                。
              </p>
              <p className="text">
                3.您在使用本软件某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请先阅读并决定是否同意相关的单独协议。您不同意该单独协议不影响您使用其他服务。
              </p>
              <p className="text">
                4.
                <b>
                  您理解并同意{
                    short
                  }将会尽其商业上的合理努力保障您在本软件及服务中的数据存储安全，但是，{
                    short
                  }并不能就此提供完全保证，包括但不限于以下情形：
                </b>
              </p>
              <p className="text">
                <b>
                  (1)因技术原因您在本软件及服务中相关数据的删除或储存可能导致失败的风险；
                </b>
              </p>
              <p className="text">
                <b>
                  (2){
                    short
                  }根据相关法律法规或实际情况，决定您在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本软件及服务中的相关数据；
                </b>
              </p>
              <p className="text">
                <b>
                  (3)如果您停止使用本软件及服务或服务被终止或取消，{
                    short
                  }将从服务器上永久地删除您的数据。因此在服务停止、终止或取消后，{
                    short
                  }无法向您返还任何数据。
                </b>
              </p>
              <p className="text">
                <b>
                  (4)如您发布、转发、传输的文件不符合监管部门的要求，将产生该文件被禁止传输、传输失败、系统自动屏蔽或删除的风险。
                </b>
              </p>
              <p className="text">
                5.
                <b>
                  您在使用本软件及服务时，须面临如下来自{
                    short
                  }不可掌控的风险内容，包括但不限于：
                </b>
              </p>
              <p className="text">
                <b>(1)由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；</b>
              </p>
              <p className="text">
                <b>(2)由于软件与您终端设备型号不相匹配所导致的任何问题或损害；</b>
              </p>
              <p className="text">
                <b>
                  (3)您在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险；
                </b>
              </p>
              <p className="text">
                <b>
                  (4)您发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；
                </b>
              </p>
              <p className="text">
                <b>
                  (5)由于无线网络信号不稳定、无线网络带宽小等原因，所引起的本软件账号登录失败、资料同步不完整、页面打开速度慢等风险。
                </b>
              </p>
              <p className="text">
                <b>
                  如遇以上情况，您可联系{short}，{
                    short
                  }将根据实际情况，积极配合您处理。
                </b>
              </p>
              <p className="text"><b>(三)第三方产品和服务</b></p>
              <p className="text">
                1.您已知晓或同意我们的服务是基于第三方如安卓系统等的技术支持获得。您已知晓本协议是在您与{
                  short
                }之间签订，而非您与上述第三方之间签订。{
                  short
                }是基于本网站和本服务所产生的内容、维护、支持服务、保证和由此产生的诉讼等事项的唯一责任人。您已同意遵守且授权给本网站和{
                  short
                }产品、程序及服务限制您有条件的使用本网站产品和服务。
              </p>
              <p className="text">
                2.您在使用本软件第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。{
                  short
                }和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。
              </p>
              <p className="text">
                3.
                <b>
                  因您使用本软件或要求{
                    short
                  }提供特定服务时，本软件可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供（包括但不限于第三方通过本软件提供的服务，或通过开放平台接入的内容等），因第三方风险引发的任何争议及损害，由第三方承担相应责任，{
                    short
                  }将为您提供必要条件，积极配合您向第三方追责。
                </b>
              </p>
              <p className="text">
                4.
                <b>
                  您理解并同意，{
                    short
                  }有权决定将本软件作商业用途，包括但不限于开发、使用本软件的部分服务为第三方作推广等，{
                    short
                  }承诺在推广过程中严格按照本协议约定保护您的个人信息，同时您亦可以根据系统设置选择屏蔽、拒绝接收相关推广信息。
                </b>
              </p>
              <p className="text">
                5.
                <b>
                  您充分了解并认可本网站和{
                    short
                  }的产品、程序及服务，且对其他您提供的用户内容负责。请您注意，根据法律规定及监管部门要求，您需规范您与其他用户的互动行为，否则可能承担因该互动产生的法律风险。{
                    short
                  }将配合监管部门进行相应处理。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">十、【用户行为规范】</p>
              <p className="text"><b>(一)信息内容规范</b></p>
              <p className="text">
                1.本条所述信息内容是指您使用本软件及服务过程中所制作、复制、发布、传播的任何内容，包括但不限于本软件账号头像、名字、用户说明等注册信息，或文字、语音、图片等发送、回复、社群图文和相关链接页面，以及其他使用本软件账号或本软件及服务所产生的内容。
              </p>
              <p className="text">
                <b>
                  2.您理解并同意，本软件一直致力于为您提供文明健康、规范有序的网络环境，您不得利用本软件账号或本软件及服务制作、复制、发布、传播如下干扰本软件正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：
                </b>
              </p>
              <p className="text">
                <b>(1)发布、传送、传播、储存违反国家法律法规禁止的内容：</b>
              </p>
              <p className="text"><b>1)违反宪法确定的基本原则的；</b></p>
              <p className="text">
                <b>2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</b>
              </p>
              <p className="text"><b>3)损害国家荣誉和利益的；</b></p>
              <p className="text">
                <b>4)煽动民族仇恨、民族歧视，破坏民族团结的；</b>
              </p>
              <p className="text">
                <b>5)破坏国家宗教政策，宣扬邪教和封建迷信的；</b>
              </p>
              <p className="text">
                <b>6)散布谣言，扰乱社会秩序，破坏社会稳定的；</b>
              </p>
              <p className="text">
                <b>7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</b>
              </p>
              <p className="text">
                <b>8)侮辱或者诽谤他人，侵害他人合法权益的；</b>
              </p>
              <p className="text">
                <b>9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</b>
              </p>
              <p className="text"><b>10)以非法民间组织名义活动的；</b></p>
              <p className="text">
                <b>
                  11)不符合《即时通信工具公众信息服务发展管理暂行规定》及未遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；
                </b>
              </p>
              <p className="text">
                <b>12)含有法律、行政法规禁止的其他内容的。</b>
              </p>
              <p className="text">
                <b>
                  (2)发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
                </b>
              </p>
              <p className="text">
                <b>(3)涉及他人隐私、个人信息或资料的；</b>
              </p>
              <p className="text">
                <b>
                  (4)发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的；
                </b>
              </p>
              <p className="text"><b>(5)进行违法犯罪活动的；</b></p>
              <p className="text">
                <b>
                  (6)其他违反法律法规、政策及公序良俗、社会公德或干扰本软件账号正常运营和侵犯其他用户或第三方合法权益内容的信息。
                </b>
              </p>
              <p className="text"><b>(二)软件使用规范</b></p>
              <p className="text">
                <b>
                  1.除非法律允许或{
                    short
                  }书面许可，您使用本软件过程中不得从事下列行为：
                </b>
              </p>
              <p className="text">
                <b>(1)删除本软件及其副本上关于著作权的信息；</b>
              </p>
              <p className="text">
                <b>
                  (2)对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
                </b>
              </p>
              <p className="text">
                <b>
                  (3)对{
                    short
                  }拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
                </b>
              </p>
              <p className="text">
                <b>
                  (4)对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非{
                    short
                  }经授权的第三方工具/服务接入本软件和相关系统；
                </b>
              </p>
              <p className="text">
                <b>
                  (5)通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
                </b>
              </p>
              <p className="text">
                <b>
                  (6)通过非{
                    short
                  }开发、授权的第三方软件、插件、外挂、系统，登录或使用{
                    short
                  }软件及服务，或制作、发布、传播上述工具；
                </b>
              </p>
              <p className="text">
                <b>
                  (7)自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；
                </b>
              </p>
              <p className="text">
                <b>(8)其他未经{short}明示授权的行为。</b>
              </p>
              <p className="text">
                <b>
                  2.您理解并同意，基于用户体验、本软件或相关服务平台运营安全、平台规则要求及健康发展等综合因素，{
                    short
                  }有权选择提供服务的对象，有权决定功能设置，有权决定功能开放、数据接口和相关数据披露的对象和范围。针对以下情形，有权视具体情况中止或终止提供本服务，包括但不限于：
                </b>
              </p>
              <p className="text"><b>(1)违反法律法规或本协议规定的；</b></p>
              <p className="text"><b>(2)影响服务体验的；</b></p>
              <p className="text"><b>(3)存在安全隐患的；</b></p>
              <p className="text">
                <b>
                  (4)与本软件账号或其服务平台已有主要功能或功能组件相似、相同，或可实现上述功能或功能组件的主要效用的；
                </b>
              </p>
              <p className="text">
                <b>
                  (5)界面、风格、功能、描述或使用者体验与本软件账号或其服务平台类似，可能造成您认为其所使用的功能或服务来源于{
                    short
                  }或经{short}授权的；
                </b>
              </p>
              <p className="text">
                <b>
                  (6)违背本软件账号或其服务平台运营原则，或不符合{
                    short
                  }其他管理要求的。
                </b>
              </p>
              <p className="text">
                <b>
                  但如您拒绝某项扩展服务，将不会影响本软件基本服务和其他已授权扩展服务的使用或受到限制。
                </b>
              </p>
              <p className="text"><b>(三)服务运营规范</b></p>
              <p className="text">
                <b>您使用本服务过程中不得从事下列行为：</b>
              </p>
              <p className="text">
                <b>1.提交、发布虚假信息，或冒充、利用他人名义的；</b>
              </p>
              <p className="text">
                <b>2.诱导其他用户点击链接页面或分享信息的；</b>
              </p>
              <p className="text">
                <b>3.虚构事实、隐瞒真相以误导、欺骗他人的；</b>
              </p>
              <p className="text">
                <b>4.侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</b>
              </p>
              <p className="text">
                <b>
                  5.未经{
                    short
                  }书面许可利用本软件账号、任何功能以及第三方运营平台进行推广或互相推广的；
                </b>
              </p>
              <p className="text">
                <b>6.利用本软件账号或本软件及服务从事任何违法犯罪活动的；</b>
              </p>
              <p className="text">
                <b>
                  7.制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
                </b>
              </p>
              <p className="text">
                <b>
                  8.其他违反法律法规规定、侵犯其他用户合法权益、干扰产品正常运营或{
                    short
                  }未明示授权的行为。
                </b>
              </p>
              <p className="text"><b>(四)对自己行为负责</b></p>
              <p className="text">
                <b>
                  1.您充分了解并同意，根据法律规定及监管部门要求，您需要为自己注册账号下的包括您所发表的任何内容以及由此产生的后果负责。您应对本服务中的内容自行加以判断，否则可能承担因使用内容而引起的包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
                </b>
              </p>
              <p className="text">
                <b>
                  2.您充分了解并保证，“手机登录”或“QQ登录”、“微信登录”、“新浪微博登录”连接APP前，请您务必事先详细阅读本协议及《隐私政策》。
                </b>
              </p>
              <p className="text">
                <b>
                  3.您充分了解并保证，您点击选择主动勾选、主动点击“同意”、
                  “注册”、“发送”、“下一步”、主动填写或提供、登录即表示您已充分知悉且同意并严格遵守相关协议、《隐私政策》或条款的所有内容。
                </b>
              </p>
              <p className="text"><b>(五)违约处理</b></p>
              <p className="text">
                <b>
                  1.如果{
                    short
                  }发现或收到他人举报或投诉您违反本协议约定的，经核实确有违约行为的，{
                    short
                  }有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，并公告处理结果。
                </b>
              </p>
              <p className="text">
                <b>
                  2.您理解并同意，{
                    short
                  }有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应承担由此产生的法律责任。
                </b>
              </p>
              <p className="text">
                <b>
                  3.您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；因您的违约行为导致{
                    short
                  }因此遭受损失的，您也应当一并赔偿。
                </b>
              </p>
              <p className="text">
                <b>
                  4.除上述责任外，{
                    short
                  }仍有权要求您改正或直接采取一切必要的措施（包括但不限于更改或删除您张贴的内容等、暂停或终止您使用本软件的权利等）以减轻您不当行为造成的影响。因此造成{
                    short
                  }损失的，{short}有权要求您给予相应的赔偿，包括但不限于{
                    short
                  }被国家机关予以行政处罚或者被第三方通过诉讼或其他方式而支付罚款、经济赔偿、以及为此而支付的相应诉讼费、律师费、差旅费、保全费等一切费用。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">十一、【广告】</p>
              <p className="text">
                1.您同意，{
                  short
                }可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更，但短信通知除外。
              </p>
              <p className="text">
                2.{
                  short
                }可能为您提供选择关闭广告信息的功能，但任何时候您都不得以本协议未明确约定或{
                  short
                }未书面许可的方式屏蔽、过滤广告信息。
              </p>
              <p className="text">
                <b>
                  3.{short}将严格依照法律法规的有关规定，对进入“{
                    APPName
                  }”平台的广告商主体资格进行严格的审查和监督，因审查和监督能力有限，{
                    short
                  }不保证、不承诺广告商所发布的广告信息的真实性和可靠性，如您依该广告信息进行了交易，或依广告内容参与了相关活动，您应当尽到合理的注意义务，自行判断广告信息的真实性并为自己的判断行为负责。除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，您可自行联系第三方广告商要求承担赔偿责任，或联系{
                    short
                  }介入，{short}将为您提供必要条件，积极配合您向第三方追责。
                </b>
              </p>
              <p className="text">
                <b>
                  4.您同意，对本软件服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">十二、【不可抗力和其他免责事由】</p>
              <p className="text">
                <b>
                  1.您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，{
                    short
                  }将努力在第一时间与相关单位配合，及时进行修复，由此给您造成的损失，{
                    short
                  }将在法律允许的范围内免责。
                </b>
              </p>
              <p className="text">
                <b>
                  2.您理解并同意，您可能将承担使用本软件或从本软件下载或以其他方式获得任何资料的风险和后果（包括但不限于受到第三方侵权或对第三方造成侵权或因任何资料的下载而导致的您设备的任何损坏或数据丢失等后果）。
                </b>
              </p>
              <p className="text">
                <b>
                  3.{
                    short
                  }对本软件不提供任何明示或默示的担保或保证，包含但不限于商业适售性、特定目的的适用性及不侵权等担保或保证。
                </b>
              </p>
              <p className="text">
                <b>
                  4.您应确认从官方渠道下载并正确使用本软件，否则您可能将承担因下列情形而发生的侵权行为：
                </b>
              </p>
              <p className="text">
                <b>
                  (1)您从非官方渠道下载本软件，并因此受到广告信息、手机病毒、恶意收费服务的影响；
                </b>
              </p>
              <p className="text">
                <b>
                  (2)您通过非本软件内（如：手机系统WLAN、其他APP管理工具）连接收费APP（通常会要求输入手机号码/账号信息），并在第三方浏览器中输入手机号码、账号信息（或动态账号信息）而造成被第三方收取上网资费的情况，因与第三方收费APP（由运营商）已签订自动登录服务，导致系统WLAN一旦连接该收费APP便会自动收取您上网资费的情况；
                </b>
              </p>
              <p className="text">
                <b>
                  (3)您通过本软件免费接入运营商APP，在网上做出的任何违反本协议、相关法律、法规规定的具体行为。
                </b>
              </p>
              <p className="text">
                <b>
                  5.本软件涉及到APP联网和Internet服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、病毒或黑客攻击、系统不稳定、您所在位置、您关机以及其他任何技术、GSM网络、互联网络、通信线路原因等造成服务中断或不能满足您要求的风险。开通服务的您可能会面临以上风险。如以上问题并非{
                    short
                  }导致，{short}仍会尽可能配合您解决上述问题。
                </b>
              </p>
              <p className="text">
                <b>
                  6.请您不要提供和分享不合法享有或获取的APP热点信息等服务。如您在使用本软件及服务中提供和分享自己合法享有的APP热点信息等服务时，应保证其所提供与分享内容的合法性与安全性，否则，您需承担由此产生的相关侵权法律责任。同时，一经发现，本软件有权对该用户进行封号处理，并保留追责权利。
                </b>
              </p>
              <p className="text">
                <b>
                  7.为了给您带来更安全便捷的APP体验，本软件为您提供了共享APP的平台，同时也对可能存在风险的APP进行及时提示，但该提示并不作为尚未认证APP的合法与安全承诺，同时我们亦不建议您在第三方APP平台进行包含但不限于资金、信息等商业行为，您在未经认证的第三方APP进行包含但不限于资金、信息等商业行为，您将面临包含但不限于资金被截取、您的个人信息被泄露等风险，如产生以上风险，{
                    short
                  }将为您提供必要条件，积极配合您向第三方追责。
                </b>
              </p>
              <p className="text">
                <b>
                  8.本软件并不自行创造内容，所有数据内容均由您主动上传。本软件仅承担存储者的角色。
                </b>
              </p>
              <p className="text">
                <b>
                  9.热点提供者（所有者）有权利要求从本软件的数据库中剔除由其（非热点所有者本人）提供的热点信息。本软件将按照法律规定予以相应处理。
                </b>
              </p>
              <p className="text">
                <b>
                  10.鉴于网络服务的特殊性，您同意本软件根据实际情况变更、中断或终止部分或全部的网络服务。如变更、中断或终止的网络服务属于免费网络服务，本软件账号无必需通知您的义务。
                </b>
              </p>
              <p className="text">
                <b>
                  11.请您理解，本软件账号需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，但本软件账号将尽可能事先进行通告。
                </b>
              </p>
              <p className="text">
                <b>
                  12.{
                    short
                  }始终在不断更改和改进服务。我们可能会增加或删除功能，也可能暂停或彻底停止某项服务。您可以随时停止使用我们的服务，尽管我们对此表示非常遗憾。{
                    short
                  }也可能随时停止向您提供服务，或随时对我们的服务增加或设置新的限制。{
                    short
                  }认为您拥有自己数据的所有权并保留对此类数据的访问权限，这一点非常重要。如果{
                    short
                  }停止某项服务，在合理可能的情况下，我们会向您发出合理的提前通知，并让您有机会在使用本软件及服务过程中知悉该信息。
                </b>
              </p>
              <p className="text">
                <b>13.本软件账号不提供以下保证（包括但不限于）：</b>
              </p>
              <p className="text"><b>1)本软件账号适合您的使用要求；</b></p>
              <p className="text">
                <b>2)本软件账号不受干扰，及时、安全、可靠或不出现错误；</b>
              </p>
              <p className="text">
                <b>
                  3)您使用经由本软件账号下载或取得的任何资料，应尽到合理的注意义务，否则您可能承担因该等使用导致您电脑系统损坏或资料流失的风险；
                </b>
              </p>
              <p className="text">
                <b>
                  14.基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，{
                    short
                  }将为您提供必要条件，积极配合您减少因相关风险造成的损失，或积极配合您向相关第三方追责：
                </b>
              </p>
              <p className="text"><b>1)对本软件账号的使用或无法使用；</b></p>
              <p className="text">
                <b>2)经由本软件账号购买或取得的任何产品、资料或服务；</b>
              </p>
              <p className="text"><b>3)您资料遭到未授权的使用或修改；</b></p>
              <p className="text"><b>4)其他与本软件账号相关的事宜。</b></p>
            </div>
            <div className="text_box">
              <p className="title">十三、【损害赔偿】</p>
              <p className="text">
                1.您理解与同意，无害的使用本网站和{
                  short
                }产品、程序及服务，避免{
                  short
                }因下述行为或相关行为遭受来自第三方的任何投诉、诉讼、损失、损害、责任、成本和费用（包括但不限于律师费）：您使用本网站和{
                  short
                }产品、程序及服务的行为；您的用户内容；您违反本协议的行为。
              </p>
              <p className="text">
                2.用户内容是指该用户下载、发布或以其他方式使用本网站与{
                  short
                }产品、程序及服务时产生的所有内容（例如：您的信息、图片、音乐或其他内容）；您是您的用户内容唯一的责任人，您将承担因您的用户内容披露而导致的您或任何第三方被识别的风险。
              </p>
              <p className="text">
                3.您理解与同意，除非获得{short}书面同意，在您与{
                  short
                }共同对第三方提起的诉讼中双方应保持行动一致。{
                  short
                }将尽合理努力将此类诉讼、诉讼行为或进程通知您。
              </p>
              <p className="text">
                <b>
                  4.如因您不当访问、使用本网站和{
                    short
                  }产品、程序及服务，您将承担所产生的损坏计算机系统或移动通讯设备数据库的风险。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">十四、【知识产权声明】</p>
              <p className="text">
                1.本软件由{
                  short
                }开发。本软件的一切版权等知识产权，以及与软件相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等均为{
                  short
                }所有，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。
              </p>
              <p className="text">
                2.本软件服务中所涉及的图形、文字或其组成，以及其他本公司标志及产品、服务名称等涉及到的知识产权，均为本公司所有或取得授权。未经本公司事先书面同意，您不得将本公司标识以任何方式展示或使用或作其他处理，也不得向他人表明您有权展示、使用、或其他有权处理本公司标识的行为。
              </p>
            </div>
            <div className="text_box">
              <p className="title">十五、【终端安全责任】</p>
              <p className="text">
                1.您理解并同意，本软件同大多数互联网软件一样，可能会受多种因素影响，包括但不限于用户原因、网络服务质量、社会环境等；也可能会受各种安全问题的侵扰，包括但不限于他人非法利用用户资料，进行现实中的骚扰；您下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您的终端设备信息和数据安全，继而影响本软件的正常使用等。因此，您应加强信息安全及个人信息的保护意识，注意账号信息保护，以免遭受损失。
              </p>
              <p className="text">
                2.您不得制作、发布、使用、传播用于窃取本软件账号及他人个人信息、财产的恶意程序。
              </p>
              <p className="text">
                <b>
                  3.维护软件安全与正常使用是{short}和您的共同责任，{
                    short
                  }将按照行业标准合理审慎地采取必要技术措施保护您的终端设备信息和数据安全。
                </b>
              </p>
              <p className="text">
                <b>
                  4.在任何情况下，您不应轻信借款、索要账号信息或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意{
                    short
                  }有关防范诈骗犯罪的提示。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">十六、【第三方软件或技术】</p>
              <p className="text">
                1.本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
              </p>
              <p className="text">
                2.本软件如果使用了第三方的软件或技术，聚合优量将按照相关法规或约定，对相关的协议或其他文件，通过本协议附件、在本软件安装包特定文件夹中打包、或通过开源软件页面等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他名称来表达。前述通过各种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。
                <b>
                  如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求{
                    short
                  }给予协助，您应当自行承担法律责任。
                </b>
              </p>
              <p className="text">
                <b>
                  3.如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，{
                    short
                  }将为您提供必要条件，积极配合您向第三方追责。{
                    short
                  }不对第三方软件或技术提供客服支持，若您需要获取支持，{
                    short
                  }将配合您与第三方联系。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">十七、【隐私保护】</p>
              <p className="text">
                {
                  short
                }将提供便捷的途径以供您对提供的信息进行访问、更正、删除、撤回同意；提供异议解决渠道，维护您的合法权益；建立安全影响评估制度与应急处置制度。更多的隐私保护可详见《隐私政策》。
              </p>
            </div>
            <div className="text_box">
              <p className="title">十八、【权利保护】</p>
              <p className="text">
                {
                  short
                }尊重知识产权，反对并打击侵犯知识产权等相关权利的行为。任何组织或个人认为{
                  short
                }提供的产品及服务（如转载文章、发布的信息、聚合优量相关平台入驻的第三方应用及其发布的信息等）可能侵犯其合法权益的，可以通过邮寄的方式将通知书及证明材料向我司提出书面权利通知，{
                  short
                }将在收到知识产权权利人合格通知后依法尽快处理，收件信息如下：
              </p>
              <p className="text">收件人：{company}</p>
              <p className="text">地址：{address}</p>
              <p className="text">邮编：{postcode}</p>
              <p className="text">电话：{phone}</p>
              <p className="text">
                或将通知书及证明材料扫描后通过电子邮件发送至电子邮箱：{email}
              </p>
            </div>
            <div className="text_box">
              <p className="title">十九、【修改与终止】</p>
              <p className="text">1.修改。</p>
              <p className="text">
                本协议内容允许变更。如果本协议项下的有关条款发生变化，我们将通过您提供的电子邮件或本网站的公告来通知您。变更通知之后，继续使用本网站和{
                  short
                }产品、程序及服务则视为您已知晓此类变更并同意受条款其约束。
              </p>
              <p className="text">
                {short}保留在任何时候修改、保留或关闭本网站、{
                  short
                }产品、程序或任何服务之权利，但会在本网站和{
                  short
                }产品上提前进行相应的公告，请您及时关注我们发布的公告，避免对您的使用造成不便。
              </p>
              <p className="text">
                您已同意{short}无需因修改、保留或关闭本网站、{
                  short
                }产品、程序或其他服务的行为对您或第三方承担责任。
              </p>
              <p className="text">2.终止。</p>
              <p className="text">
                <b>
                  本协议自您接受之日起生效，在您使用本网站和{
                    short
                  }产品、程序及服务的过程中持续有效，直至依据本协议终止。
                </b>
              </p>
              <p className="text">
                尽管有上述规定，如果您使用本网站和{
                  short
                }产品、程序及服务的时间早于您接受本协议的时间，您在此知晓或应当知晓并同意本协议于您第一次使用本网站和{
                  short
                }产品、程序及服务时生效，除非依据本协议提前终止。
              </p>
              <p className="text">
                3.我们可能会：依据法律的规定，保留您使用本网站、{
                  short
                }产品、程序及服务、或者本网站账号的权利；无论是否通知，我们将在根据本协议的相关约定及法律规定终止本协议，包括出于善意的相信您违反了我们可接受使用政策或本协议的其他规定。
              </p>
              <p className="text">
                4.不受前款规定所限，如果您侵犯第三人的版权且{
                  short
                }接到版权所有人或版权所有人的合法代理人的通知后，{
                  short
                }保留终止本协议的权利。
              </p>
              <p className="text">
                5.一旦本协议终止，您的网站账号和使用本网站和{
                  short
                }产品、程序及服务的权利即告终止。您应当知晓您的网站账号终止意味着您的用户内容将从我们的活动数据库中删除。
              </p>
              <p className="text">
                6.任何本网站的更新版本或{
                  short
                }产品、程序及服务的未来版本、更新或者其他变更将受到本协议约束。
              </p>
            </div>
            <div className="text_box">
              <p className="title">二十、【管辖和法律】</p>
              <p className="text">
                1.本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
              </p>
              <p className="text">
                <b>
                  2.若您和{
                    short
                  }之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即中国上海市静安区）有管辖权的人民法院管辖。
                </b>
              </p>
            </div>
            <div className="text_box">
              <p className="title">二十一、【其他说明】</p>
              <p className="text">
                <b>
                  1.您通过本软件使用广告商、外部链接指向的网站或资源或第三方提供的内容或服务时，亦应遵从所适用的附加条款。
                </b>
              </p>
              <p className="text">
                <b>
                  2.本协议内容，同时包括{
                    short
                  }可能不断发布的关于本服务的相关协议、业务规则等内容，您在使用本软件的某一特定服务时，该服务可能会另有单独的协议、相关业务规则等，该内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。
                </b>
              </p>
              <p className="text">
                <b>
                  3.如果本协议的任何部分无效或无法执行，此部分应以与适用法律相一致的方式诠释，并尽量反映各方的本来意图，其他条款将继续保留完全的效力。{
                    short
                  }不行使或不执行本协议项下的任何权利或本协议的任何条款，并不构成对该等权利或条款的放弃。您同意，除了本协议中另行明确规定的外，本协议不存在任何第三方受益人。
                </b>
              </p>
              <p className="text">
                4.本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
              </p>
              <p className="text">
                5.本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
              </p>
              <p className="text">
                6.本软件相关活动只限中国境内（包括香港特别行政区、澳门特别行政区和台湾地区）{
                  short
                }用户在境内参与和使用。
              </p>
            </div>
            <div className="text_box">
              <p className="title">二十二、【联系方式】</p>
              <p className="text">文件收寄地址：{address}</p>
              <p className="text">联系邮箱：{email}</p>
              <p className="text">联系电话：{phone}</p>
            </div>
          </div> :
          <div className="main_box">
            <div className="text_box">
              <p className="text"><b>更新日期：2023年6月5日</b></p>
              <p className="text"><b>更新日期：2023年6月5日</b></p>
            </div>
            <div className="text_box">
              <p className="title">引言</p>
              <p className="text">{company}（以下简称为{short}或我们）尊重并保护所有用户的个人信息及隐私安全。为同时给您提供更准确的服务和更安全的互联网环境，我们依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》以及其他相关法律法规和技术规范明确了我们收集/使用/对外提供个人信息的原则，进一步阐述了关于您个人信息的相关权利。</p>
              <p className="text">本政策与您所使用的我们的产品与服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与服务（以下统称“使用我们的产品与服务”）时，我们将按照本政策的约定处理和保护您的个人信息。我们尽量以简明扼要的表述向您解释本政策所涉及的技术词汇，以便于您理解。本政策中与您权益（可能）存在重大关系的条款，我们已使用加粗字体予以区别，请您重点查阅。</p>
              <p className="text">请在使用/继续使用我们的各项产品与服务前，仔细阅读并充分理解本政策，并在需要时，按照本政策的指引，做出适当的选择。如果您不同意本政策的内容，将可能导致我们的产品与服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与服务。您使用或继续使用我们提供的产品与服务的行为，都表示您充分理解和同意本《{short}用户隐私政策》（包括更新版本）的全部内容。</p>
            </div>
            <div className="text_box">
              <p className="title">关于我们</p>
              <p className="text">{APPName}的经营者为{company}。</p>
              <p className="text">注册地址：{signAddr}</p>
            </div>
            <div className="text_box">
              <p className="title">一、我们如何收集和使用您的个人信息</p>
              <p className="text">我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用我们服务过程中主动提供或因使用我们产品和服务而产生的个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将已经收集的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意：</p>
              <p className="text">1.账号注册及登录</p>
              <p className="text">1.1.当您注册及登录时，如您使用一键登录的功能，基于我们与通信运营商的合作，我们会收集您的<b>手机号码和手机运营商信息</b>，以便为您提供快捷的登录服务；同时，为帮助您完成注册过程，我们还将收集您的<b>兴趣</b>，以为您提供初始服务。</p>
              <p className="text">1.2.<b>如您使用第三方账号进行登录，为了满足网络实名制要求，在您使用发布、评论及其他要求实名认证的功能与服务前，我们将另行收集您的手机号码以完成实名认证。</b>请您谨慎考虑后提供这类信息，若您拒绝提供，您可能无法正常使用相应的功能。</p>
              <p className="text">1.3.您还可以根据自身需求选择填写或更改昵称、头像、出生年月日、教育经历（院校及入学时间）、地区及个人介绍来完善您的信息，但如您不提供此类信息，可能会影响您使用本产品和相关服务。</p>
              <p className="text">1.4.如果您成功注册{APPName}账号，我们会在{APPName}中显示您的用户名、头像、地区、个人介绍信息。</p>
              <p className="text">1.5.<b>我们会收集设备的唯一标识（Android ID、OAID、mac），用于身份标识和数据统计，以便于我们更好地分析用户行为并推动产品的优化。我们承诺不会将唯一标识（AndroidID、OAID、mac）用于任何不符合隐私政策的目的。</b></p>
              <p className="text">1.6.我们可能会收集您当前连接的WiFi的SSID（服务集标识符）和BSSID（基本服务集标识符）信息。这些信息主要用于以下目的：</p>
              <div className="text">
                <p>1)定位服务：通过WiFi信息辅助定位，提供更准确的地理位置服务。</p>
                <p>2)网络诊断：分析WiFi连接状态，优化网络连接性能。</p>
                <p>3)热点连接优化：根据WiFi信息，为您推荐更优质的热点连接。</p>
                <p>在收集这些信息之前，我们会先获得您的明确授权。如果您拒绝授权，我们将不会收集这些信息。同时，我们承诺对收集到的WiFi
                  SSID和BSSID进行加密处理，并采取严格的访问控制措施，以防止信息泄露。</p>
                <p>这些信息将仅用于内部服务优化、故障排查等目的。如果需要与第三方共享这些信息，我们会明确告知您，并获得您的同意。同时，我们会与第三方签署严格的信息保护和保密协议，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</p>
                <p>您有权查询自己的WiFiSSID和BSSID信息，并在发现信息不准确时提出更正要求。如果您不再希望我们收集这些信息，可以通过应用程序内的设置或联系我们进行删除或撤回授权。</p>
                <p>我们可能会根据法律法规的要求或业务发展的需要，对隐私政策进行更新。如果隐私政策发生变更，我们将通过应用程序内通知或其他方式及时通知您，并说明变更的内容和原因。对于重大变更，我们可能需要重新获得您的同意。</p>
              </div>
              <p className="text">2.向您提供产品和服务</p>
              <p className="text">2.1.信息浏览、发布、点赞、收藏、分享功能。</p>
              <p className="text">2.1.1.为了向您提供我们最核心的信息展示服务并向您展示帖子及相关信息，我们会收集您的<b>点击操作记录（如兴趣、点击、搜索、点赞、收藏、分享、评论、发布记录）。您的点击操作记录属于个人敏感信息，但属于提供最核心的信息展示和信息发布服务所必需的信息。</b></p>
              <p className="text">2.1.2.当您使用发布、编辑、图片、音视频功能时，我们会请求您授权照片、相机、麦克风权限。您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用其他功能。</p>
              <p className="text">2.1.3.当您使用发布、评论、点赞、收藏、分享功能时，<b>您发布的文字、照片、视频、音频、评论、点赞、收藏、分享记录信息会存储在我们的服务器中，</b>因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。</p>
              <p className="text">2.2.安全运行。为了保障软件与服务的安全运行，我们会收集您的设备型号、设备名称、设备唯一标识符、浏览器类型和设置、语言设置、操作系统和应用程序版本、网络设备硬件地址、登录IP地址、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号。</p>
              <p className="text">2.3.搜索功能。当您使用{APPName}搜索功能时，我们会收集<b>您查询的关键词、阅读记录和访问时间、评论和互动记录 。</b>收集此信息可以向您提供您所需要的内容，同时亦可以改进我们的产品和服务。</p>
              <p className="text">2.4.互动功能与服务。</p>
              <p className="text">2.5.身份认证功能及账号找回。</p>
              <p className="text">2.5.1.当您使用身份认证功能申请成为认证用户时，我们可能会收集您的身份信息，包括<b>您的真实姓名、身份证号码、面部识别信息，用于证实您的个人身份。</b></p>
              <p className="text">2.5.2.当您使用账号找回功能时，我们可能会收集您的身份信息，包括<b>您的真实姓名、身份证号码、面部识别信息，用于核实您的身份，</b>便于您找回{APPName}账号。</p>
              <p className="text"><b>这些信息是个人敏感信息，您可以拒绝提供，且不会影响聚合优量其他功能的正常使用。</b></p>
              <p className="text">2.6.客服和售后服务功能</p>
              <p className="text">当您联系我们的客服或提出我们的产品与服务的售后申请时，为了您的账号与系统安全，我们可能需要您提供相关个人信息与您之前提供的个人信息相匹配以验证您的用户身份。验证成功后，我们可能会收集您与我们的沟通信息（例如：账号信息、您为了证明相关事实而提供的图片/视频/文字信息）、您的联系方式以及您与我们的沟通记录，包括线上沟通记录、电话录音，以便尽快为您解决问题和改进我们的产品与服务。</p>
              <p className="text">4.征得授权同意的例外</p>
              <p className="text">根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</p>
              <p className="text">(1)与我们履行法律法规规定的义务相关的；</p>
              <p className="text">(2)与国家安全、国防安全直接相关的；</p>
              <p className="text">(3)与公共安全、公共卫生、重大公共利益直接相关的；</p>
              <p className="text">(4)与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
              <p className="text">(5)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
              <p className="text">(6)所收集的个人信息是您自行向社会公众公开的；</p>
              <p className="text">(7)从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</p>
              <p className="text">(8)根据您与平台签署的在线协议或合同所必需的；</p>
              <p className="text">(9)用于维护我们产品和服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
              <p className="text">(10)法律法规规定的其他情形。</p>
            </div>
            <div className="text_box">
              <p className="title">二、我们如何共享、转让、公开披露您的个人信息</p>
              <p className="text">1.共享：</p>
              <p className="text">对于您的个人信息，我们不会与任何公司、组织和个人进行共享，除非存在以下一种或多种情形：</p>
              <p className="text"><b>(1)事先已得到您的授权；</b></p>
              <p className="text"><b>(2)您自行提出的；</b></p>
              <p className="text"><b>(3)与商业合作伙伴的必要共享：</b></p>
              <p className="text"><b>您理解并知悉，为了向您提供更完善、优质的产品和服务，我们将授权商业合作伙伴为您提供部分服务。此种情形下，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。请您注意，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们将对信息数据的输出形式、流转、使用进行安全评估与处理，以保护数据安全。</b></p>
              <p className="text">目前，我们的合作伙伴包括以下类型：</p>
              <p className="text">A.数据服务供应商：包括网络广告监测的合作伙伴。为维护/改进我们的产品/服务、为您提供更好的内容，我们可能会与提供该服务的指定合作伙伴共享您的相关信息（含广告播放/展示记录、设备标识符 ）。请您知悉，为了您的信息安全，我们目前仅与已签署严格数据安全保密协议的合作伙伴进行合作；但我们承诺：未经您的同意，我们不会与其共享可以识别您身份的个人信息。</p>
              <p className="text">B.内容审核服务商：为保证用户在互联网信息平台上发布的内容是安全的，我们可能会向委托的第三方内容审核服务商共享您在平台上发布的内容信息；</p>
              <p className="text">C.实名认证审核服务商：当您进行实名认证或使用找回账号功能时，我们会向第三方审核服务商共享您的个人信息，以便您顺利找回账号。</p>
              <p className="text">D.第三方小程序：当您使用第三方小程序时，未经您同意，我们不会向这些开发者、运营者共享您的个人信息。当您使用小程序时，小程序可能会使用您授权的相关系统权限，您可以在相应的小程序中撤回该等授权；</p>

              <p className="text">E.合作的第三方SDK服务商：当您使用{APPName}中由第三方提供的功能时，我们可能会接入由第三方提供的软件开发包（SDK）以实现相关功能。此时，第三方SDK服务商可能会收集您相关的个人信息：</p>
              <p className="text">a.一键登录功能：为给您提供账号登录——一键登录功能，第三方服务商可能会获取您的必要设备信息、网络相关信息、地理位置信息;</p>
              <p className="text">b.第三方账号登录功能：为给您提供第三方账号登录的功能，第三方服务商可能会获取您的必要设备信息、网络相关信息、地理位置信息；</p>
              <p className="text">c.消息推送 SDK Android：开发者:每日互动股份有限公司 SDK隐私政策链接：
                <a href="https://docs.getui.com/privacy/">
                  https://docs.getui.com/privacy/
                </a>
                。为给您提供消息推送，第三方推送服务商可能会获取您的推送SDK版本号、必要设备信息、手机状态信息、地理位置信息、网络相关信息以便推送信息；
              </p>
              <p className="text">d.地理位置服务：为给您提供位置服务（如发现功能、附近功能、打卡功能），第三方地理位置服务商会收集您的设备信息及精准地理位置信息以便可以向您推送附近内容；</p>
              <p className="text">e.云储存服务：为给您提供云端储存服务（如您上传至聚合优量的图片、音视频内容会储存在云端），第三方服务商可能会收集您的设备信息、网络相关信息、运营商信息；</p>
              <p className="text">f.账号安全服务：为保障您和其他用户的账号与财产安全，使您和我们的正当合法权益免受不法侵害，第三方安全服务商可能会收集您的必要设备信息、网络相关信息、运营商信息、日志信息以提供账号安全服务；</p>
              <p className="text">g.移动智能终端补充设备标识体系统一调用SDK：开发者:中国信息通信研究院 SDK隐私政策链接：<a href="https://www.msa-alliance.cn/col.jsp?id=122">https://www.msa-alliance.cn/col.jsp?id=122</a>，我们将收集用户设备的唯一标识，用来统计数据、分析服务，以便用来优化APP功能，为用户提供更好的客户服务和用户体验。</p>
              <p className="text">(4)当您选择参加我们及我们的关联方或平台上的第三方举办的营销活动时，<b>可能需要您提供姓名、性别、通信地址、联系方式、银行账号信息。这些信息是个人敏感信息，拒绝提供可能会影响您参加相关活动，但不会影响其他功能。</b>只有经过您的同意，我们才会将这些信息与关联方或第三方共享，以保障您在活动中获得体验一致的服务，或委托第三方及时向您兑现奖励。</p>
              <p className="text">(5)与我们的关联公司必要共享：我们可能会与我们的关联公司共享您的个人信息。我们只会共享必要的个人信息，且这种共享受本政策所声明的目的的约束。关联公司如要改变个人信息的处理目的，将再次征得您的授权和同意；</p>
              <p className="text">(6)您可以基于{short}与第三人（包括不特定对象）共享您的个人信息或其他信息，但因您的共享行为而导致的信息泄露、被使用等情况，与{short}无关，{short}不因此承担法律责任。</p>
              <p className="text">2.转让：</p>
              <p className="text">转让是指将取得您个人信息的控制权转让给其他公司、组织或个人。除非获取您的明确同意，否则我们不会将您的个人信息转让给任何公司、组织或个人。但下述情形除外：</p>
              <p className="text">(1)事先已征得您的同意；</p>
              <p className="text">(2)您自行提出的；</p>
              <p className="text">(3)如果公司发生合并、收购或破产清算，将可能涉及到个人信息转让，此种情况下我们会告知您有关情况并要求新的持有您个人信息的公司、组织继续受本政策的约束。否则我们将要求其重新获取您的明示同意；</p>
              <p className="text">(4)其他法律法规规定的情形。</p>
              <p className="text">3. 公开披露：</p>
              <p className="text">公开披露是指向社会或不特定人群发布信息的行为。原则上，我们不会对您的个人信息进行公开披露。但下述情况除外：</p>
              <p className="text">(1)因公布账号中奖、处罚通知时展示必要的相关脱敏信息；</p>
              <p className="text">(2)取得您的明示同意后。</p>
              <p className="text">4.共享、转让、公开披露个人信息授权同意的例外情形</p>
              <p className="text">根据相关法律法规的规定，在以下情形中，我们可能在未事先征得您的授权同意的情况下共享、转让、公开披露您的个人信息：</p>
              <p className="text">(1)与我们履行法律法规规定的义务相关的，含依照法律规定、司法机关或行政机关强制性要求向有权机关披露您的个人信息；在该种情形下，我们会要求披露请求方出具与其请求相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施；</p>
              <p className="text">(2)与国家安全、国防安全直接相关的；</p>
              <p className="text">(3)与公共安全、公共卫生、重大公共利益直接相关的；</p>
              <p className="text">(4)与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
              <p className="text">(5)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
              <p className="text">(6)您自行向社会公众公开的个人信息；</p>
              <p className="text">(7)从合法公开披露的信息中收集到的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
              <p className="text">(8)法律法规规定的其他情形。</p>
              <p className="text">请您了解，根据现行法律规定及监管要求，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，无需另行向您通知并征得您的同意。</p>
            </div>
            <div className="text_box">
              <p className="title">三、我们如何存储和保护您的个人信息</p>
              <p className="text">1.存储：</p>
              <p className="text">存储地点：我们将从中华人民共和国境内获得的个人信息存放于中华人民共和国境内。如果发生个人信息的跨境传输，我们会单独向您以站内通知或邮件的方式告知您个人信息出境的目的、接收方，并征得您的授权同意，我们会严格遵守中国的法律法规，确保数据接收方有充足的数据保护能力来保护您的个人信息。</p>
              <p className="text">存储时间：我们承诺始终按照法律的规定在合理必要期限内在存储您个人信息。超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。</p>
              <p className="text">如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</p>
              <p className="text">2.保护：</p>
              <p className="text">为了保护您的个人信息安全，我们将努力采取各种符合行业标准的安全措施来保护您的个人信息以最大程度降低您的个人信息被毁损、盗用、泄露、非授权访问、使用、披露和更改的风险。我们将积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。</p>
              <p className="text">您的账户均有安全保护功能，请妥善保管您的账户及密码信息。{short}将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。为防止安全事故的发生，我们已按照法律法规的规定，制定了妥善的预警机制和应急预案。如确发生安全事件，我们将及时将相关情况选择以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。</p>
              <p className="text">3.当我们的产品或服务发生停止运营的情形时，我们会及时停止继续收集个人信息的活动。上述变更，我们将以推送通知、公告等形式通知你，并在合理的期限内删除你的个人信息或进行匿名化处理（所谓“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。）</p>
            </div>
            <div className="text_box">
              <p className="title">四、您如何管理您的个人信息</p>
              <p className="text"><b>我们非常重视并尽全保障您对自己个人信息的相关权利。</b></p>
              <p className="text">1.自主决定接收信息</p>
              <p className="text">在关注中会根据您主动选择关注的账号信息进行展示，您可以根据需要自主选择关注或取消关注。</p>
              <p className="text">2.自行访问、更正和删除相关信息</p>
              <p className="text">我们通过交互界面的设计，为您提供了可自行访问、更正和删除相关个人信息的渠道，您可通过以下方式进行设置：</p>
              <p className="text">(1)通过我-设置-编辑资料访问、更正自己的个人资料，含头像、昵称、性别、常住地、院校及入学时间、生日、个人介绍；</p>
              <p className="text">(2)通过我-设置-帐号与安全设置、访问、更正账号信息，含手机号（每月仅可换绑一次）、登录密码；</p>
              <p className="text">(3)通过我-收藏/赞过访问、更正、删除相关信息。</p>
              <p className="text">3.变更或撤回授权范围</p>
              <p className="text">您也可以通过设备本身操作系统变更或撤回麦克风、通讯录、摄像头、相册权限，我们将根据您变更或撤回权限的设置进行个人信息的收集，如您撤回授权我们将不再收集与这些权限相关的信息，<b>但提请您注意这将导致部分功能或全部功能的不可用。同时，您变更或撤回授权的决定，不会影响我们此前基于您的授权而进行的个人信息处理。</b></p>
              <p className="text">4.联系我们进行管理</p>
              <p className="text">出于安全性和身份识别的考虑，您可能无法直接通过{APPName}交互界面自行访问、更正或删除某些信息（如实名认证后的信息、您的部分使用记录）；如您确有必要访问、修改或依法要求删除该类信息，请您按照本政策第十条中所提供的联系方式，将您的问题通过邮件发送至指定的联系邮箱，我们将尽快审核所涉问题，并在核验您的用户身份后及时予以回复。</p>
            </div>
            <div className="text_box">
              <p className="title">五、您如何注销您的账号</p>
              <p className="text"><b>您可以通过我-设置-帮助与客服-社区问题-如何注销账号或我-设置-账号-注销账号，在满足账号注销的条件下选择申请注销账号，来注销您的账号。</b></p>
              <p className="text"><b>注销内容：账号注销后，除法律明确规定必须由我们保留的个人信息外，您的个人信息将会从聚合优量移除。您所有的发帖数据以及互动数据均会删除。</b></p>
              <p className="text"><b>注销时效：账号注销将在您完成操作后立即生效。</b></p>
            </div>
            <div className="text_box">
              <p className="title">六、有关第三方提供产品和服务的特别说明</p>
              <p className="text">{APPName}中可能包括第三方产品和服务或链接至第三方提供的信息和服务，<b>该等第三方产品和服务在使用前，您需要跳转到相应的小程序或第三方页面。您使用该等第三方服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非本政策）约束，您需要仔细阅读其条款并自行决定是否接受。</b>请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。本政策仅适用于我们所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，<b>我们对任何第三方使用由您提供的信息不承担任何责任。</b></p>
            </div>
            <div className="text_box">
              <p className="title">七、我们如何使用Cookie和其他同类技术</p>
              <p className="text">(1)在您未拒绝接受cookies的情况下，{APPName}在您的计算机以及相关移动设备上设定或取用cookies，以便您能登录或使用依赖于cookies的{short}平台服务或功能。{APPName}使用cookies可为您提供更加周到的服务。</p>
              <p className="text">(2)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式或在移动设备中设置拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的聚合优量平台服务或功能。</p>
              <p className="text">(3)通过{APPName}所设cookies所取得的有关信息，将适用本政策。</p>
              <p className="text">第三方SDK的使用：为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。</p>
            </div>
            <div className="text_box">
              <p className="title">八、未成年人保护</p>
              <p className="text"><b>以下条款请未成年用户 （特别是未满十四周岁的儿童用户）在监护人的陪同下仔细阅读，并由监护人在充分理解后作出是否接受或拒绝本政策的决定：</b></p>
              <p className="text"><b>1.若用户是未满18周岁的未成年人（特别是未满十四周岁的儿童用户），应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用{APPName}相关服务。</b></p>
              <p className="text"><b>2.我们重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用{APPName}相关服务 。</b></p>
              {/* <p className="text"><b>3.我们已设计开发“青少年模式”，监护人可通过我-设置-青少年模式开启；功能开启后，{APPName}将只能在特定时段和时长内使用，且首页中将仅展示我们精选的教育类、知识类内容，同时无法进行点赞、分享、评论等操作。</b></p> */}
              <p className="text"><b>4.若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您根据本政策第十条提供的联系方式及时与我们联系。我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</b></p>
              <p className="text"><b>5.未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。</b></p>
            </div>
            <div className="text_box">
              <p className="title">九、我们如何更新隐私政策</p>
              <p className="text">1.我们保留不时更新或修订本政策的权利。这些更新与修订将构成本政策的一部分并具有等同于本政策的效力，未经您明确同意，我们不会削减您依据当前生效的本政策所应享受的权利。</p>
              <p className="text">2.如遇本政策更新，我们会通过APP客户端推送通知等合理方式通知您，以便您能及时了解本政策所做的任何变更。</p>
            </div>
            <div className="text_box">
              <p className="title">十、如何联系我们</p>
              <p className="text">如您对个人信息保护问题有任何投诉、建议、疑问，或您对本政策有任何疑问，您可以通过以下方式联系我们，我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内予以答复：{email}。</p>
            </div>
            <div className="text_box">
              <p className="title">十一、其他</p>
              <p className="text"><b>1.因本政策以及我们处理您个人信息事宜引起的任何争议，您可诉至上海市静安区人民法院。</b></p>
              <p className="text"><b>2.如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。</b></p>
            </div>
            <div className="text_box">
              <p className="title">十二、附录-权限申请、使用目的及业务场景</p>
              <p className="text"><b>1.APP权限：网络访问权限</b>描述：访问网络信息 使用目的及业务场景：从服务端更新数据</p>
              <p className="text"><b>2.APP权限：电话权限Android</b>描述：读取手机状态使用目的及业务场景：读取手机状态，涉及到用户设备的IMEI信息，用于进行用户数据统计（安装、活跃度等）及识别用户、保障您的帐号、网络、运营及系统安全，降低盗号风险</p>
              <p className="text"><b>3.APP权限：存储权限（写入）Android</b>描述：写入外部存储使用目的及业务场景：用于将用户拍摄完毕的图片、视频保存到本地等的文件保存功能。</p>
              <p className="text"><b>4.APP权限：地理位置权限</b>描述：接收卫星的定位信息使用目的及业务场景：获取精确位置地理位置的权限。用于用户带有地理位置的水印、素材的获取、第三方广告投放。</p>
              <p className="text"><b>5.APP权限：麦克风权限</b>描述：录制声音使用目的及业务场景：用于视频录制功能：包括相机模式、视频模式、声音录制。</p>
              <p className="text"><b>6.APP权限：相机权限</b>描述：允许使用相机使用目的及业务场景：用于拍照、视频录制的功能：包括相机、视频。</p>
              <p className="text"><b>7.APP权限：存储权限（读取）Android</b>描述：读取外部存储使用目的及业务场景：读取本地文件、图片、视频，用于帮助用户进行图片、视频美化。</p>
              <p className="text"><b>8.APP权限：通知权限</b>描述：向用户发送通知使用目的及业务场景：向用户推送平台活动、营销信息等。</p>
              <p className="text"><b>9.APP权限：下载安装包Android</b>描述：下载安装包 使用目的及业务场景：应用更新、推送安装包等。</p>
              <p className="text"><b>10.APP权限：相册读取、写入权限IOS</b>描述：允许使用手机相册使用目的及业务场景：写入、读取本地文件、图片、视频，用于帮助用户进行图片、视频美化。</p>
              <p className="text"><b>11.APP权限：日历权限</b>描述：允许读取和写入日历使用目的及业务场景：读取时间，提供时间水印素材。</p>
              <p className="text"><b>12.APP权限：清除用户数据</b>描述：允许清除应用的用户数据使用目的及业务场景：用户退出登录时需要该权限。</p>
              <p className="text"><b>13.APP权限：设置应用参数</b>描述：设置应用的参数，已不再工作具体查看介绍使用目的及业务场景：完善UI适配，防止按钮错位。</p>
            </div>
          </div>
      }

      <div className="tips_box">
        <p>上海迈势绮信息科技有限公司<a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备2023019017号-1</a></p>
      </div>
    </div>
  )
}
export default Agreement