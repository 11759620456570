/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 17:19:23
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-13 13:41:10
 * @FilePath: \line_web\src\pages\share\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './index.scss'
const Feature = () => {
  return (
    <div className="feature_page">
      <div className="main_box">
        <p className="title_box">应用功能介绍</p>
        <ol className="list_box">
          <li className="item_box">用户可分享自己的生活方式，满足社交需求。</li>
          <li className="item_box">用户可增加自己的社交圈，在聊天互动中认识新友，随时随地进入聊天室，加入聊天</li>
          <li className="item_box">用户可在平台上找到自己的校友</li>
          <li className="item_box">数据安全：用户隐私保护措施</li>
          <li className="item_box">服务器稳定性：确保聊天和分享的流畅性</li>
          <li className="item_box">结合聊天交友与生活分享</li>
        </ol>
      </div>
    </div>
  )
}
export default Feature