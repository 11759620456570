/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 17:19:23
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-13 13:49:00
 * @FilePath: \line_web\src\pages\share\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './index.scss'
const Authority = () => {
  return (
    <div className="authority_page">
      <div className="main_box">
        <p className="title_box">应用权限说明</p>
        <ol className="list_box">
          <li className="item_box">
            <p className="title">修改或删除您的SD卡中的内容</p>
            <p className="text">允许应用写入SD卡。</p>
          </li>
          <li className="item_box">
            <p className="title">读取手机状态和身份</p>
            <p className="text">允许该应用访问设备的电话功能。此权限可让该应用确定本机号码和设备 ID、是否正处于通话状态以及拨打的号码。</p>
          </li>
          <li className="item_box">
            <p className="title">读取您的SD卡中的内容</p>
            <p className="text">允许应用读取您SD卡的内容。</p>
          </li>
          <li className="item_box">
            <p className="title">查看网络连接</p>
            <p className="text">允许该应用查看网络连接的相关信息，例如存在和连接的网络。</p>
          </li>
          <li className="item_box">
            <p className="title">拥有完全的网络访问权限</p>
            <p className="text">允许该应用创建网络套接字和使用自定义网络协议。浏览器和其他某些应用提供了向互联网发送数据的途径，因此应用无需该权限即可向互联网发送数据。</p>
          </li>
          <li className="item_box">
            <p className="title">查看WLAN连接</p>
            <p className="text">允许该应用查看WLAN网络的相关信息，例如是否启用了WLAN以及连接的WLAN设备的名称。</p>
          </li>
          <li className="item_box">
            <p className="title">控制振动</p>
            <p className="text">允许应用控制振动器。</p>
          </li>
          <li className="item_box">
            <p className="title">检索正在运行的应用</p>
            <p className="text">允许该应用检索近期运行的和当前正在运行的任务的相关信息。此权限可让该应用了解设备上使用了哪些应用。</p>
          </li>
          <li className="item_box">
            <p className="title">访问确切位置信息（以 GPS 和网络为依据）</p>
            <p className="text">允许该应用通过全球定位系统(GPS)或网络位置信息源（例如基站和WLAN）获取您的精确位置信息。您必须在设备上开启这些位置信息服务，应用才能获得位置信息。应用会使用此类服务确定您的位置，这可能会消耗更多电量。</p>
          </li>
          <li className="item_box">
            <p className="title">访问大致位置信息（以网络为依据）</p>
            <p className="text">允许该应用获取您的大致位置信息。这类位置信息来自于使用网络位置信息源（例如基站和WLAN）的位置信息服务。您必须在设备上开启这些位置信息服务，应用才能获得位置信息。应用会使用此类服务确定您的大概位置。</p>
          </li>
          <li className="item_box">
            <p className="title">请求安装文件包</p>
            <p className="text">允许应用请求安装文件包。</p>
          </li>
          <li className="item_box">
            <p className="title">拍摄照片和视频</p>
            <p className="text">允许该应用使用相机拍摄照片和视频。此权限可让该应用随时使用相机，而无需您的确认。</p>
          </li>
          <li className="item_box">
            <p className="title">录音</p>
            <p className="text">允许该应用使用麦克风录制音频。此权限可让该应用不经您的确认即可随时录制音频。</p>
          </li>
          <li className="item_box">
            <p className="title">更改您的音频设置</p>
            <p className="text">允许该应用修改全局音频设置，例如音量和用于输出的扬声器。</p>
          </li>
        </ol>
      </div>
    </div>
  )
}
export default Authority