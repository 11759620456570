/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2025-02-12 14:50:26
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-13 15:41:14
 * @FilePath: \maishiqi_web\src\App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 导入路由
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// 导入页面组件
import Home from './pages/Home'
import Agreemeny from './pages/Agreement'
import Download from './pages/Download'
import Authority from './pages/Authority'
import Feature from './pages/Feature'
import NotFound from './pages/NotFound'
function App () {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/agreement" element={<Agreemeny />} />
          <Route path="/download" element={<Download />} />
          <Route path="/authority" element={<Authority />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
