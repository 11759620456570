/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2025-02-12 14:50:26
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-12 15:16:18
 * @FilePath: \maishiqi_web\src\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
