/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 17:19:23
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2024-08-15 10:58:31
 * @FilePath: \line_web\src\pages\share\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './index.scss'
const NotFound = () => {
  return (
    <div className="notfound_page">
      <p className="title">404</p>
      <p className="text">访问页面不存在！</p>
    </div>
  )
}
export default NotFound